import { Action } from 'redux';
import { names as companyNames } from 'shared/actions/user/fetchCompanies';
import { Employee } from 'shared/types/Employee';
import { patch_employee_names } from 'shared/hooks/company/useEmployeeUpdate';

export interface EmployeesState {
  [id: number]: Employee;
}

interface EmployeesAction {
  type: string;
  payload: {
    entities: {
      employees: EmployeesState;
    };
  };
}

function isEmployeesAction(action: Action<any>): action is EmployeesAction {
  return action.type === companyNames.success;
}

function isEmployeePatch(action: Action<any>): action is EmployeesAction {
  return action.type === patch_employee_names.success;
}

// eslint-disable-next-line @typescript-eslint/default-param-last
const employees = (state: EmployeesState = {}, action: Action<any>) => {
  if (isEmployeePatch(action)) {
    const payload = action.payload;
    return { ...state, ...payload };
  }

  if (isEmployeesAction(action)) {
    const { entities } = action.payload;
    return { ...state, ...entities.employees };
  }

  return state;
};

export default employees;
