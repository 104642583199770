import { useCallback, useState } from 'react';
import { AppRouting } from 'shared/utils/AppRouting';
import axios from 'shared/utils/axios';
import useCurrentCompany from 'shared/hooks/company/useCurrentCompany';
import { AxiosError } from 'axios';
import errorLogger from 'shared/utils/errorLogger';
import notify from 'shared/actions/notify';
import { useTranslation } from 'react-i18next';
import useAppDispatch from 'shared/hooks/useAppDispatch';
import useAppSelector from 'shared/hooks/useAppSelector';
import { asyncNames, createActions } from 'shared/actions/utils';
import addMinutes from 'date-fns/addMinutes';
import isBefore from 'date-fns/isBefore';

export const patch_employee_names = asyncNames('PATCH_EMPLOYEE');
export const actions = createActions(patch_employee_names);

const useEmployeeUpdate = ({
  onUpdateSuccess,
}: {
  onUpdateSuccess?: () => void;
}) => {
  const dispatch = useAppDispatch();
  const [pending, setPending] = useState(false);
  const [trans] = useTranslation();

  const { employees, id: companyId } = useCurrentCompany();
  const employeeId = employees[0];

  const employeeList = useAppSelector(state => state.employees);
  const elevatedPrivileges =
    employeeList?.[employeeId]?.elevatedPrivileges || null;

  const now = new Date();
  const elevatedPrivilegesCurrentState =
    elevatedPrivileges && new Date(elevatedPrivileges);

  const elevatedPrivilegesUpdate = addMinutes(now, 30);

  const isCurrentStateBefore =
    elevatedPrivilegesCurrentState &&
    isBefore(now, elevatedPrivilegesCurrentState);

  const handleError = useCallback(
    (ex: AxiosError) => {
      errorLogger(ex);
      const responseErrors = ex.response?.data?.errors;
      const globalErrors = responseErrors?.global;
      if (globalErrors) {
        setPending(false);
        dispatch(notify({ message: globalErrors }));
        return;
      }
      if (responseErrors) {
        setPending(false);
        dispatch(notify({ message: responseErrors }));
        return;
      }
      dispatch(notify({ message: trans('ui|||error|||default') }));
      setPending(false);
    },
    [dispatch, trans],
  );

  const handleUpdate = useCallback(
    async (up: boolean) => {
      if (!employeeId) {
        return;
      }
      setPending(true);
      try {
        const url = AppRouting.generate(
          'api_patch_employee',
          {
            employee_id: employeeId,
            company_id: companyId,
          },

          true,
        );

        const response = await axios.patch(url, {
          elevatedPrivileges: up ? elevatedPrivilegesUpdate : null,
        });

        const employeeData = response?.data;
        dispatch(actions.success({ [employeeId]: employeeData }));

        setPending(false);
        if (onUpdateSuccess) {
          onUpdateSuccess();
        }
      } catch (ex) {
        handleError(ex);
      }
    },
    [companyId, employeeId, handleError, onUpdateSuccess],
  );

  return {
    pending,
    handleUpdate,
    elevatedPrivilegesCurrentState: isCurrentStateBefore
      ? elevatedPrivilegesCurrentState
      : null,
  };
};

export type UpdateEmployee = ReturnType<typeof useEmployeeUpdate>;

export default useEmployeeUpdate;
